<template>
	<ShutterPanel />
</template>

<script type="text/javascript">
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'

	export default {
		name: 'PlanningMonteMain',
		mixins: [Shutter, MonteShutters],
		props: {
			planning_date: { type: String, default: null }
		},
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				let shutter = this.monteShutters['monte-main-calendar-beta']
				shutter.props.planning_date = this.planning_date
				this.shutterPanel().open(shutter)			
			},
		},

		computed: {
		},

		components: {
			ShutterPanel
		}
	}

</script>